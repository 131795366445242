body, html {
  margin: 0;
  padding: 0;
}
.container {
  width: 1000px;
  margin: 0 auto;
}
.row {
  overflow-y: auto;
}
.row > .block {
  width: 50%;
  float: left;
}
.row > .col-third {
  width: 33%;
  float: left;
}
ul {
  list-style: none;
}
.mt-30 {
  margin-top: 30px;
}
header {
  background-color: rgb(230, 111, 0);
}
header h1 {
  margin-top: 0;
  padding-top: 20px;
  font-size: 55px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 0;
  padding-bottom: 0;
}
header p {
  margin-top: 0;
  padding-bottom: 20px;
}
p.error {
  color: red;
  font-weight: bold;
  margin-top: 0;
}
.content {
  padding: 30px 0;
}
.content input {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #000;
  outline: none;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
}
.content input.small {
  width: 90px;
  font-size: 16px;
}
.content label {
  font-weight: bold;
}
.content .graph {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 99%;
  border: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
}